<template>
  <div>
    <section
      class="home-head"
      :style="{
        'background-image':
          'url(' + `${publicPath}assets/images/home-head-bg.jpg` + ')'
      }"
    >
      <div class="container">
        <div class="home-head-content-wrap">
          <div class="home-head-content">
            <h1 class="head-title" v-html="$t(`homePage.downloadMusic`)"></h1>
            <UiSocial></UiSocial>
          </div>
          <div class="home-head-img-wrap img-hover-zoom">
            <img
              class="home-head-main-img"
              :src="`${publicPath}assets/images/home-head.png`"
              alt=""
            />
            <div class="home-head-img-lists">
              <img
                class="home-head-img"
                v-for="(album, index) in albums"
                :src="album"
                :key="index"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-why-trebel">
      <h2 class="section-title">{{ $t("homePage.whyTrebel") }}</h2>
      <div class="container">
        <b-row class="why-trebel-row">
          <b-col md="6" order-md="1" order="2">
            <div class="why-trebel-img-wrap">
              <b-img
                :src="`${publicPath}assets/images/why-trebel.png`"
                fluid
                alt="Why TREBEL?"
                class="why-trebel-img"
              ></b-img>
            </div>
          </b-col>
          <b-col md="6" order-md="2" order="1">
            <div class="list-items-wrap">
              <ul class="list-items">
                <li>
                  <div class="list-item-title">
                    {{ $t("homePage.largeExtensiveCatalog") }}
                  </div>
                  <div class="list-item-text">
                    {{ $t("homePage.MoreThen15M") }}
                  </div>
                </li>
                <li>
                  <div class="list-item-title">
                    {{ $t("homePage.noWiFI") }}
                  </div>
                  <div
                    class="list-item-text"
                    v-html="$t('homePage.playMusicOffline')"
                  ></div>
                </li>
                <li>
                  <div class="list-item-title">
                    {{ $t("homePage.onDemandListening") }}
                  </div>
                  <div
                    class="list-item-text"
                    v-html="$t('homePage.playWhatYouWant')"
                  ></div>
                </li>
                <li>
                  <div class="list-item-title">
                    {{ $t("homePage.backgroundPlay") }}
                  </div>
                  <div
                    class="list-item-text"
                    v-html="$t('homePage.listenToYourMusic')"
                  ></div>
                </li>
                <li>
                  <div class="list-item-title">
                    {{ $t("homePage.longerBatteryLife") }}
                  </div>
                  <div class="list-item-text">
                    {{ $t("homePage.whileStreamingMusic") }}
                  </div>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </div>
    </section>
    <section class="home-ad">
      <h2 class="home-ad-title">{{ $t("homePage.advertisement") }}</h2>
      <div class="container">
        <a
          :href="
            $i18n.locale === 'us'
              ? 'https://www.instagram.com/trebelmusic/'
              : 'https://www.instagram.com/trebelmx/'
          "
          target="_blank"
        >
          <img
            :src="`${publicPath}assets/images/large-banner-${$i18n.locale}.png`"
            alt=""
            class="img-fluid"
          />
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import UiSocial from "@/components/UI/UiSocial.vue";
export default {
  name: "Home",
  metaInfo() {
    return {
      title: this.$t("meta.home.title"),
      titleTemplate: null,
      htmlAttrs: {
        lang: this.$t("lang")
      },
      meta: this.generateMeta({
        title: this.$t("meta.home.title"),
        description: this.$t("meta.home.description"),
        url: this.generateUrl(this.locale)
      })
    };
  },
  components: {
    UiSocial
  },
  computed: {
    locale() {
      return this.$store.getters.locale;
    }
  },
  data() {
    return {
      albums: [
        `${this.publicPath}assets/images/album/album-1.png`,
        `${this.publicPath}assets/images/album/album-2.png`,
        `${this.publicPath}assets/images/album/album-3.png`,
        `${this.publicPath}assets/images/album/album-4.png`,
        `${this.publicPath}assets/images/album/album-5.png`,
        `${this.publicPath}assets/images/album/album-6.png`,
        `${this.publicPath}assets/images/album/album-7.png`
      ]
    };
  }
};
</script>
